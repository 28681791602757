import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: 'AIzaSyDovLKo3djdRbs963vqKdbj-geRWyzMTrg',
	projectId: 'enviro-indoor',
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
