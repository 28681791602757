import React from 'react';

import { LuminanceStyled } from './luminance.styled';

type LuminanceProps = {
	lux: number | undefined;
};

const Luminance = ({ lux }: LuminanceProps) => (
	<LuminanceStyled letterCount={lux ? lux.toString().length + 4 : 6}>{lux} lux</LuminanceStyled>
);

export default Luminance;
