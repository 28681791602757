import React from 'react';

import { TemperatureStyled } from './temperature.styled';

type TemperatureProps = {
	degrees: number | undefined;
};

const Temperature = ({ degrees }: TemperatureProps) => (
	<TemperatureStyled letterCount={degrees ? degrees.toString().length + 2 : 6}>
		{degrees} &deg;C
	</TemperatureStyled>
);

export default Temperature;
