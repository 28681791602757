import React from 'react';

import { HumidityStyled } from './humidity.styled';

type HumidityProps = {
	percentage: number | undefined;
};

const Humidity = ({ percentage }: HumidityProps) => (
	<HumidityStyled letterCount={percentage ? percentage.toString().length + 2 : 6}>
		{percentage} %
	</HumidityStyled>
);

export default Humidity;
