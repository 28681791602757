import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
	body {
		font-family: 'Aldrich', sans-serif;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		margin: 0;
		overflow: hidden;

		--bg-color: #800080FF;
		--bg-color-radial: #FF2BFFFF;
		background-color: var(--bg-color);
		background-blend-mode: multiply;
		background-image: repeating-radial-gradient(circle at 0 0, transparent 0, var(--bg-color) 10px), repeating-linear-gradient(orange, var(--bg-color-radial));
		opacity: 0.8;
		animation: bganimation 60s infinite;
	}

	main {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	time {
		position: absolute;
		bottom: -1rem;
		left: 0;
		cursor: default;
		color: darkorange;
		transition: color 1s;

		&:hover {
			color: white;
		}
	}

	@keyframes bganimation {
		50% {
			background-color: #B300B3FF;
		}
	}

`;
