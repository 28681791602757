import React, { useEffect, useState } from 'react';

import { doc, onSnapshot } from 'firebase/firestore';

import { db } from '../../firebase';
import AirPressure from '../air-pressure';
import Humidity from '../humidity';
import Luminance from '../luminance/Luminance';
import Temperature from '../temperature';

import { GlobalStyles } from './global-styles.styled';

type Readings = {
	temperature: number;
	humidity: number;
	pressure: number;
	luminance: number;
};

type FirebaseDoc = {
	readings: Readings;
	createdAt: string;
};

const App = () => {
	const [temperature, setTemperature] = useState(0);
	const [humidity, setHumidity] = useState(0);
	const [airPressure, setAirPressure] = useState(0);
	const [luminance, setLuminance] = useState(0);
	const [lastUpdate, setLastUpdate] = useState('');

	const getRealtimeReadings = () => {
		const docRef = doc(db, 'werkkamer', 'last_update');
		return onSnapshot(docRef, doc => {
			// @ts-ignore
			const { readings, createdAt } = doc.data() as FirebaseDoc;
			if (readings) {
				setTemperature(readings.temperature);
				setHumidity(readings.humidity);
				setAirPressure(readings.pressure);
				setLuminance(readings.luminance);
			}
			setLastUpdate(createdAt || '');
		});
	};

	useEffect(() => {
		const unsubscribeFromFirebase = getRealtimeReadings();
		return () => {
			unsubscribeFromFirebase();
		};
	}, []);

	return (
		<>
			<GlobalStyles />
			<Temperature degrees={temperature} />
			<Humidity percentage={humidity} />
			<Luminance lux={luminance} />
			<AirPressure hectopascals={airPressure} />

			<time>{new Date(lastUpdate).toLocaleString()}</time>
		</>
	);
};

export default App;
