import styled from 'styled-components';

const calcSize = (letterCount: number) => 100 / (letterCount - 2);

type PosterViewItemProps = {
	letterCount: number;
};

export const PosterViewItem = styled.div<PosterViewItemProps>`
	cursor: default;

	font-weight: 900;
	font-size: ${({ letterCount }) => calcSize(letterCount)}vw;

	height: ${({ letterCount }) => calcSize(letterCount)}vw;
	width: 100%;

	text-align: justify;
	text-shadow: 0 0.125rem 0.125rem deeppink;
	color: orange;
	filter: drop-shadow(0 0 0.313rem darkorange);

	transition: transform 1s ease, color 1s;
	animation: textanimation 10s infinite;

	@media (min-width: 48rem) {
		font-size: ${({ letterCount }) => calcSize(letterCount)}vh;
		height: ${({ letterCount }) => calcSize(letterCount)}vh;
	}

	&:after {
		content: '';
		display: inline-block;
		width: 100%;
		height: 0;
	}

	&:hover {
		color: white;
		transform: translateX(2rem);
	}

	@keyframes textanimation {
		50% {
			text-shadow: 0.2rem 0 0.125rem deeppink;
		}
	}
`;
