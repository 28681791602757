import React from 'react';

import { AirPressureStyled } from './air-pressure.styled';

type AirPressureProps = {
	hectopascals: number | undefined;
};

const AirPressure = ({ hectopascals }: AirPressureProps) => (
	<AirPressureStyled letterCount={hectopascals ? hectopascals.toString().length + 4 : 11}>
		{hectopascals} hPa
	</AirPressureStyled>
);

export default AirPressure;
